import React from "react";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import MenuMobile from "../components/MenuMobile";
import "../styles/About.css";
//import IconCloud from '../components/IconCloud';
import Writer from "./Writer";

function Blog() {
    return (
        <body>
            <TitleBar />
            <section className="flex-container">
                <section className="main-container">
                    <Header />
                    <MenuMobile />
                    <main className="main-wrapper about">
                        <div className="about-container">
                            <span className="span-tag html">{"<html>"}</span>
                            <br />
                            <span className="span-tag body">{"<body>"}</span>
                            <br />
                            <span className="span-tag content">{"<h1>"}</span>
                            <h1 className="title-about">My Blog</h1><br/>

                            <span className="span-tag content">{"</h1>"}</span>
                            <br />
                            <span className="span-tag content">{"<p>"}</span>
                            {/* <p className="text-about">In progress...</p> */}

                            <p className="text-about">
                                {/* My name is <span className="span-highlight">Ciprian Chiches.</span> Hi, I’m a Software Developer based in Resita, Romania. For more than a year, I developed beautiful and responsive web applications that satisfied all the needs of clients in order to launch or improve their current businesses.<span className="span-highlight"></span> */}
                            </p>
                            <p className="text-about">
                                {/* <span className="span-highlight">Soft Skills</span> */}
                                <br />
                              My thoughts related to career in software development, passions and life can be read 
  <br />
                                {/* <a href="https://pluralsight.com">here</a> */}
                                <a href="https://ciprianblog.netlify.app/" style={{color:"rgb(254, 190, 16)"}}>here</a>
                                <br />
                                ...
                                <br />
                                "Special" blog for fans of comics and Marvel movies <br/>
                                
                                <a href="https://marvelcomicsblog.netlify.app/" style={{color:"rgb(254, 190, 16)"}}>here</a>
                                &nbsp;- in progress -

                                <br />
                                ... <br/>
                                Blog for Front End Developers <br/>
                                
                                <a href="https://frontenddevelopmentblog.netlify.app/" style={{color:"rgb(254, 190, 16)"}}>here</a>
                                &nbsp;- in progress -

                                <br />
                                ...{" "}
                                
                            </p>
                            <p className="text-about last">
                                {/* Relevant skills include: <span className="span-highlight">Html, CSS, JavaScript, React, Redux, ContextAPI, Hooks, Jest/RTL, Git/Github.</span> */}
                            </p>
                            <span className="span-tag content">{"</p>"}</span>
                            <br />
                            <span className="span-tag body">{"</body>"}</span>
                            <br />
                            <span className="span-tag html">{"</html>"}</span>
                        </div>
                        {/* <IconCloud /> */}
                        <Writer />
                    </main>
                </section>
                <SideBar />
            </section>
            <Footer />
        </body>
    );
}

export default Blog;
