import React, { useState } from "react";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import MenuMobile from "../components/MenuMobile";
import projects from "../data/projects";
import "../styles/Projects.css";

import topproject from "../images/topproject.png";
import { FiPlay } from "react-icons/fi";
import { Typewriter } from "react-simple-typewriter";



import Home2 from "./Home2";

// import Home3 from "./Home3";


//import { FiExternalLink, FiGithub } from "react-icons/fi";

//import android from "./android.jpg";

//import android from "../data/android";

//import Button from "@mui/material/Button";

function Projects() {
    const [typeFilter, setTypeFilter] = useState("all");
    const filterProjects = () => {
        if (typeFilter === "all") return projects;
        return projects.filter(({ type }) => type.includes(typeFilter));
    };
    return (
        <body>

{/* <Home2 className="home2" /> */}

            

{/* <Home3/>             */}
            <TitleBar />

            
            <section className="flex-container">
                <section className="main-container">



                    <Header />

                  


                    <MenuMobile />




                    {/* <main className="main-wrapper home" style={{height:"160px"}}>

<div className="home-content" >
      
        <h1 style={{color:"rgb(254, 190, 16)"}}>
        <span className="span" style={{color:"rgb(254, 190, 16)"}}>{"<"}</span>           More Projects bellow 
            <span className="span" style={{color:"rgb(254, 190, 16)"}}>{"/>"}</span>{" "}
        </h1>
        </div>
</main> */}



                   
                    <main className="main-wrapper projects">
                        <h1 className="title-page-projects" style={{margin:"auto"}} >My Projects</h1>


                    {/* top project */}
                    <section className="flex-container" >
                    <section className="main-container" style={{margin:"auto"}}>

                    <main className="main-wrapper home"  >
                        <div className="home-content" >
                            <p style={{color:"rgb(254, 190, 16)"}}>
                                <span className="span" style={{color:"rgb(254, 190, 16)"}}>{"<"}</span>My Top
                                Project
                            </p>
                            <h1 >
                                Full Stack <br />
                                Movie Application
                                <span className="span" style={{color:"rgb(254, 190, 16)"}}>{"/>"}</span>{" "}
                            </h1>
                            <span style={{ color: "#FEBE10" }}>
                                <Typewriter
                                    words={[
                                        " Watch Movies and Tv Series for Free.",
                                        "Read Reviews.",
                                        "Download Photos.",
                                        " Watch Trailers.",
                                        // " Strength athlete.",
                                        // " Historian.",
                                        // " Comics fan.",
                                    ]}
                                    loop
                                    cursor
                                    cursorStyle="|"
                                    typeSpeed={70}
                                    deleteSpeed={50}
                                    delaySpeed={1000}
                                />
                            </span>

                            {/* <Typical 
                steps={['Front-End Developer', 1000, 'Web Development Student', 1000]}
                loop={Infinity}
                wrapper="h2"
              /> */}
                            <a
                                href="https://peaceful-smakager-95d419.netlify.app/"
                                target="_blank"
                                className="btn-download"
                                rel="noreferrer"
                            >
                                Watch Online
                                <FiPlay />
                            </a>


                        </div>

                       

                        <img src={topproject} alt="" className="image-perfil" />


                    </main>

                    </section>
                    </section>
                    
                    {/* final top project */}

                        <div className="btn-container">
                            <button
                                type="button"
                                className={`btn-filter ${
                                    typeFilter === "all" && "active"
                                }`}
                                onClick={() => setTypeFilter("all")}
                            >
                                All
                            </button>
                            <button
                                type="button"
                                className={`btn-filter ${
                                    typeFilter === "front" && "active"
                                }`}
                                onClick={() => setTypeFilter("front")}
                            >
                                Front-end
                            </button>
                            <button
                                type="button"
                                className={`btn-filter ${
                                    typeFilter === "Figma Design" && "active"
                                }`}
                                onClick={() => setTypeFilter("Figma Design")}
                            >
                                Figma Design
                            </button>

                            <button
                                type="button"
                                className={`btn-filter ${
                                    typeFilter === "back" && "active"
                                }`}
                                onClick={() => setTypeFilter("back")}
                            >
                                Back-end
                            </button>
                        </div>

                        <button
                            type="button"
                            className={`btn-filter ${
                                typeFilter === "android" && "active"
                            }`}
                            onClick={() => setTypeFilter("android")}
                        >
                            Android Apps
                        </button>
                        {/* <img src={android} alt="" style={{objectFit:"contain"}}/> */}

                        <div className="projects-container">
                            {filterProjects().map(
                                (
                                    {
                                        type,
                                        name,
                                        src,
                                        repository,
                                        description,
                                        site,
                                        technologies,
                                    },
                                    index
                                ) => (
                                    <div key={index} className="card-project">
                                        <div className="img-container">
                                            <img
                                                src={src}
                                                alt="Capture from project"
                                                className="img-project"
                                                style={{ objectFit: "contain" }}
                                            />
                                        </div>

                                        <h2 className="title-project">
                                            {name}
                                        </h2>
                                        <p>{description}</p>
                                        <div className="technologies-container">
                                            {technologies.map((tech) => (
                                                <span className="span-technologies">
                                                    {tech}
                                                </span>
                                            ))}
                                        </div>
                                        <div className="links-container">
                                            <a
                                                href={repository}
                                                title="Github"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {/* <FiGithub
                                                    style={{
                                                        color: "white",
                                                        marginTop:"1.5rem"
                                                        
                                                    }}
                                                /> */}
                                            </a>

                                            <a
                                                href={site}
                                                title="Live Demo"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <button className="live-demo">
                                                    {" "}
                                                    <p
                                                        style={{
                                                            color: "white",
                                                            fontSize: "1rem",

                                                            // textDecoration:
                                                            //     "none",
                                                        }}
                                                    >
                                                        Live Demo
                                                    </p>
                                                </button>

                                                {/* <FiExternalLink /> */}
                                            </a>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </main>
                </section>
                <SideBar />
            </section>
            <Footer />
        </body>
    );
}

export default Projects;
