import React from "react";
import '../styles/TitleBar.css';
import Arrow from "../pages/Arrow";

function TitleBar() {
  return ( 
    <section className="title-bar">
      <p>ciprian_chiches_portfolio</p>
      <div className="title-btn">
        <span className="close"/>
        <span className="minimize"/>
        <span className="maximize"/>
      </div>
    </section>
  );
}

export default TitleBar;