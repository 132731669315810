import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const Messenger = () => {
	return (
		<WhatsAppWidget
			phoneNo="+40724278194"
			position="right"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={true}
			autoOpenTimer={2000}
			messageBox={true}
			messageBoxTxt="Hi, I wanna talk to you."
			iconSize="40"
			iconColor="white"
			iconBgColor="tomato"
			// headerIcon="https://proficientdesigners.in/wp-content/themes/pd/img/logo-new.png"
			headerIconColor="pink"
			headerTxtColor="black"
			headerBgColor="tomato"
			headerTitle="Ciprian Chiches"
			headerCaption="Online"
			bodyBgColor="#FEBE10"
			chatPersonName="Support"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="#999"
			btnBgColor="#FEBE10"
			btnTxtColor="black"
			btnTxt="Start Chat"
		/>
	);
};

export default Messenger;