import WSPGallery from "./components/WSPGallery";

function Slider() {
    const galleryImages = [
        {
            img: "olor2.jpg",
        },
        {
            img: "olor.jpg",
        },

        {
            img: "sauna.jpg",
        },
        {
            img: "trans.jpg",
        },

        {
            img: "trans2.jpg",
        },

        {
            img: "trans3.jpg",
        },
        {
            img: "frana.jpg",
        },
        {
            img: "directie.png",
        },
        {
            img: "gume.jpg",
        },
        {
            img: "sala.jpg",
        },
        {
            img: "bazin.jpg",
        },
        {
            img: "cinema.jpg",
        },
        {
            img: "fitness.jpg",
        },
        {
            img: "1.jpg",
        },{
            img: "2.jpg",
        },{
            img: "3.jpg",
        },{
            img: "4.jpg",
        },
        {
            img: "5.jpg",
        },{
            img: "6.jpg",
        },
    ];

    return (
        <div className="App">
            <br />
            <div>
                <strong style={{ color: "#FEBE10", fontSize: "1.5rem" }}>
                    Click for Zoom
                </strong>
            </div>
            <br />
            <br />

            <WSPGallery galleryImages={galleryImages} />

            <br />
            <br />
            {/* <div>- WebStylePress -</div> */}
        </div>
    );
}

export default Slider;
