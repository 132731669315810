import React from "react";
import "../styles/Header.css";
import MenuBar from "../components/MenuBar";
import react_icon from "../images/react_icon.svg";
import js_icon from "../images/js_icon.svg";
import html_icon from "../images/html_icon.svg";
//import css_icon from "../images/css_icon.svg";
import json_icon from "../images/json_icon.svg";
import node from "../images/node.svg";
import sass from "../images/sass.png";
//import creation from "../images/creation.png";
import careers from "../images/careers.png";

function Header() {
    return (
        <header className="header-container">
            <nav className="nav-container">
                <MenuBar icon={react_icon} name="home.jsx" path="/" />
                <MenuBar icon={html_icon} name="about.html" path="/about" />
                <MenuBar icon={js_icon} name="projects.js" path="/projects" />
                <MenuBar
                    icon={json_icon}
                    name="education.json"
                    path="/education"
                />

                <MenuBar icon={node} name="blog.node" path="/blog" />
                <MenuBar icon={sass} name="graphic.sass" path="/graphic" />
                {/* <MenuBar icon={css_icon} name="contact.css" path="/contact" /> */}
                <MenuBar icon={careers} name="hire.me" path="/career" />
                {/* <MenuBar 
       icon={ creation }
          name='skills'
          path="/skills"
        />    
         */}
            </nav>
        </header>
    );
}

export default Header;
