import React from "react";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import MenuMobile from "../components/MenuMobile";
import "../styles/Graphic.css";
//import IconCloud from '../components/IconCloud';
//import Writer from "./Writer";

//import Slider from "../components/Slider/Slider";
//import Slider from "../components/Slider/Slider";
// import Slider from "../components/Slider";
// import SliderImages from "../components/Slider/SliderImages";
//import Image from "../components/Slider/Image";
import Slider from "../components/Slider/Slider";
import Design from "./Design";

function Graphics() {
    return (
        <body>
            <TitleBar />
            <section className="flex-container">
                <section className="main-container">
                    <Header />
                    <MenuMobile />
                    <main className="main-wrapper about">
                        <div className="about-containers">
                            <span className="span-tag html" >{"<html>"}</span>
                            <br />
                            <span className="span-tag body">{"<body>"}</span>
                            <br />
                            <span className="span-tag content">{"<h1>"}</span>
                            <h1
                                className="title-about"
                                // style={{ fontSize: "2rem" }}
                            >
                                Graphic Design
                            </h1>

                            {/* <br /> */}

                            <span className="span-tag content">{"</h1>"}</span>
                            <br />
                            <span className="span-tag content">{"<p>"}</span>
                            <br />
                            {/* <Slider /> */}
                            {/* <Slider/> */}
                            {/* <SliderImages/> */}
                            {/* <Image/> */}
                            <Slider/>

                            <span className="span-tag content">{"</p>"}</span>
                            <br />
                            <span className="span-tag body">{"</body>"}</span>
                            <br />
                            <span className="span-tag html">{"</html>"}</span>
                        </div>
                        {/* <IconCloud /> */}
                        <Design/>

                        {/* <Writer /> */}
                    </main>

                </section>
                <SideBar />
            </section>
            <Footer />
        </body>
    );
}

export default Graphics;
