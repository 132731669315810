const projects = [
    {
        type: "front",
        name: "Cinema Resita Website",
        description: "Website create for a Movie Theater.",
        src: "cinema.png",
        repository: "https://github.com/CiprianChiches/cinemaDacia2023",
        site: "https://cinemadacia.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Bazin Olimpic Resita",
        description: "Website create for a Olympic Swimming Pool.",
        src: "bazin.png",
        repository: "https://github.com/CiprianChiches/bazin-olimpic",
        site: "https://bazinolimpicresita.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Rocket Game",
        description: "Online Game",
        src: "game.png",
        repository: "https://github.com/CiprianChiches/cipi-game",
        site: "https://cipiracegame.netlify.app/",
        technologies: ["React", "Redux", "CSS3", "HTML5", "ES6"],
    },
    {
        type: "front",
        name: "Movie Application",
        description: "Website for Movies and Tv Shows lovers",
        src: "cinemasearch.png",
        repository: "https://github.com/CiprianChiches/cinemadacia-databasemovies",
        site: "https://cinemaresita-database.netlify.app/",
        technologies: ["React", "Redux", "CSS3", "HTML5", "ES6"],
    },
    {
        type: "front",
        name: "Coffee Shop - Template",
        description: "Website for Coffee lovers",
        src: "coffee.png",
        repository: "https://github.com/CiprianChiches/coffee-aplication",
        site: "https://cipicoffeeshop.netlify.app/",
        technologies: ["React", "Redux", "CSS3", "HTML5", "ES6"],
    },
    // {
    //     type: "back",
    //     name: "Private Chat",
    //     description: "Website for chatting and sharing photos",
    //     src: "chat.png",
    //     repository: "https://github.com/CiprianChiches/messenger-react",
    //     site: "https://messenger-react-pi.vercel.app/login",
    //     technologies: ["React", "Firebase"],
    // },
    // {
    //   type: 'UI/UX Design',
    //   name: 'UI/UX Restaurant - Template',
    //   description: 'Website for a restaurant',
    //   src: 'ux.png',
    //   repository: 'https://github.com/CiprianChiches/restaurantUx',
    //   site: 'https://uxuirestaurant.netlify.app/',
    //   technologies: ['React','Figma'],
    // },
    // {
    //   type: 'UI/UX Design',
    //   name: 'UI/UX  Website - Template',
    //   description: 'Presentation website',
    //   src: 'modern.png',
    //   repository: 'https://github.com/CiprianChiches/modern-UI',
    //   site: 'https://uiuxmodernwebsite.netlify.app/',
    //   technologies: ['React','Figma'],
    // },
    {
        type: "front",
        name: "Education & Courses - Template",
        description: "Presentation website",
        src: "curs.png",
        repository: "https://github.com/CiprianChiches/edu-web",
        site: "https://remotecourses.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Blog & Fun - Template",
        description: "Presentation website ",
        src: "blog.png",
        repository: "https://github.com/CiprianChiches/edu-web",
        site: "https://bloggingpresentation.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Skin Care - Template",
        description: "Presentation website",
        src: "glow.png",
        repository: "https://github.com/CiprianChiches/glowing-js",
        site: "https://skincarewebsite.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Restaurant Website - Template",
        description: "Presentation website",
        src: "restaurant.png",
        repository: "https://github.com/CiprianChiches/restaurant-app",
        site: "https://cipirestaurant.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Groot Game",
        description: "Funny Game website",
        src: "groot.png",
        repository: "https://github.com/CiprianChiches/groot-app",
        site: "https://grootgame.netlify.app/",
        technologies: ["React", "CSS3", "HTML5", "ES6"],
    },
    {
        type: "front",
        name: "Blog for Books - Template",
        description: "Presentation website",
        src: "books.png",
        repository: "https://github.com/CiprianChiches/books-js",
        site: "https://blogforbooklovers.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "back",
        name: "Coffee Shop - Template",
        description: "Presentation website",
        src: "fullcoffee.png",
        repository: "https://github.com/CiprianChiches/fullStack-coffee",
        site: "https://cipionlinecoffee.netlify.app/",
        technologies: [
            "React",
            "CSS3",
            "HTML5",
            "ES6",
            "Heroku",
            "React-Stripe.js",
        ],
    },
    {
        type: "front",
        name: "Books Store - Template",
        description: "Presentation website",
        src: "book.png",
        repository: "https://github.com/CiprianChiches/booken",
        site: "https://ildibooksonline.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    // {
    //   type: 'front',
    //   name: 'Fitness App - Template',
    //   description: 'Website for fitness lovers',
    //   src: 'power.png',
    //   repository: 'https://github.com/CiprianChiches/gymmadeinreact',
    //   site: 'https://powerbuilding.netlify.app/',
    //   technologies: ['React', 'CSS3', 'HTML5', 'ES6','EmailJs'],
    // },
    {
        type: "front",
        name: "Nutrition Blog - Template",
        description: "Presentation website",
        src: "fructe.png",
        repository: "https://github.com/CiprianChiches/nutrition-app",
        site: "https://nutritionbycipi.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Astronomy App",
        description: "Website for astronomy lovers",
        src: "nasa.png",
        repository: "https://github.com/CiprianChiches/astronomy-app",
        site: "https://cipiastronomy.netlify.app/",
        technologies: ["React", "CSS3", "HTML5", "ES6"],
    },
    {
        type: "front",
        name: "Gym Website - Template",
        description: "Website for a Gym",
        src: "resitagym.png",
        repository: "https://github.com/CiprianChiches/fitnesstudio",
        site: "https://resitagym.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    // {
    //     type: "front",
    //     name: "Gym Website - Template",
    //     description: "Website for a Gym",
    //     src: "resitagym2.png",
    //     repository: "https://github.com/CiprianChiches/fitlife",
    //     site: "https://powerbodybuilding.netlify.app/",
    //     technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    // },
    // {
    //   type: 'front',
    //   name: 'Barber Shop - Template',
    //   description: 'Website for a Beauty Salon',
    //   src: 'barber.png',
    //   repository: 'https://github.com/CiprianChiches/barber-js',
    //   site: 'https://barberbycipi.netlify.app/',
    //   technologies: ['CSS3', 'HTML5', 'ES6', 'JavaScript'],
    // },
    // {
    //   type: 'front',
    //   name: 'Construction Company - Template',
    //   description: 'Website for a Construct Company',
    //   src: 'construct.png',
    //   repository: 'https://github.com/CiprianChiches/cosntruction-js',
    //   site: 'https://cipiconstructions.netlify.app/',
    //   technologies: ['CSS3', 'HTML5', 'ES6', 'JavaScript'],
    // },
    {
        type: "front",
        name: "Podcast Website - Template",
        description: "Website for Podcasting",
        src: "podcast.png",
        repository: "https://github.com/CiprianChiches/podcast-js",
        site: "https://cipipodcast.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Save the Animals - Template",
        description: "Website for Biologists",
        src: "wild.png",
        repository: "https://github.com/CiprianChiches/wild-js",
        site: "https://wildanimales.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    // {
    //   type: 'UI/UX Design',
    //   name: 'UI/UX Website - Template ',
    //   description: 'Presentation website',
    //   src: 'banca.png',
    //   repository: 'https://github.com/CiprianChiches/reactuxui-design',
    //   site: 'https://ciprianchiches-makes-great-sites.netlify.app/',
    //   technologies: ['React','Tailwind.css','Vite.dev'],
    // },
    {
        type: "front",
        name: "E-Commerce - Template ",
        description: "Presentation website",
        src: "adidasi.png",
        repository: "https://github.com/CiprianChiches/selling-js",
        site: "https://cipiselling.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    // {
    //     type: "front",
    //     name: "YouTube Clone",
    //     description: "Presentation website",
    //     src: "youtube.png",
    //     repository: "https://github.com/CiprianChiches/youtube-clone",
    //     site: "https://cipiyoutubeclone.netlify.app/",
    //     technologies: ["React", "CSS3", "HTML5", "ES6"],
    // },
    {
        type: "back",
        name: "Video Call Application",
        description: "Presentation website",
        src: "call.png",
        // repository: 'https://github.com/CiprianChiches/youtube-clone',
        site: "https://cipivideocall.netlify.app/",
        technologies: ["React", "CSS3", "HTML5", "ES6", "Heroku", "Tabnine"],
    },
    {
        type: "front",
        name: "Coffee Shop - Template",
        description: "Presentation website",
        src: "cipicoffee.png",
        repository: "https://github.com/CiprianChiches/cipicoffee-js",
        site: "https://cipicoffee.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Photography - Template",
        description: "Presentation website",
        src: "photo.png",
        repository: "https://github.com/CiprianChiches/photography-js",
        site: "https://cipiphotography.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    // {
    //   type: 'front',
    //   name: 'Telephone Presentation - Template',
    //   description: 'Presentation website',
    //   src: 'iphone.png',
    //   repository: 'https://github.com/CiprianChiches/iphone-presentation',
    //   site: 'https://presentationtelephoneapp.netlify.app/',
    //   technologies: ['React', 'CSS3', 'HTML5', 'ES6','React Three-Fiber','Netlify','Tabnine'],
    // },
    {
        type: "front",
        name: "Car Service - Template",
        description: "Presentation website",
        src: "car.png",
        repository: "https://github.com/CiprianChiches/car-service",
        site: "https://cipicarrepair.netlify.app/",
        technologies: ["CSS3", "HTML5", "ES6", "JavaScript"],
    },
    // {
    //   type: 'front',
    //   name: 'Photography Services - Template',
    //   description: 'Presentation website',
    //   src: 'photoblog.png',
    //   repository: 'https://github.com/CiprianChiches/blogPhoto-js',
    //   site: 'https://cipiphotoblog.netlify.app/',
    //   technologies: ['CSS3', 'HTML5', 'ES6', 'JavaScript'],
    // },
    {
        type: "back",
        name: "Furniture Shop - Template",
        description: "Presentation website",
        src: "mobila.png",
        repository: "https://github.com/CiprianChiches/furniture-shop",
        site: "https://cipifurniture.netlify.app/",
        technologies: ["HTML5", "CSS3", "ES6", "React", "Stripe"],
    },
    // {
    //   type: 'android',
    //   name: 'Cinema Dacia Android App',
    //   description: 'App for a Movie Theatre (Beta Version)',
    //   src: 'android.jpg',
    //    repository: 'https://github.com/CiprianChiches/cinemaDacia2023',
    //   //  site: 'https://drive.google.com/file/d/10IHYXnpvzkZXqohPquWiH4zLO3tRUloM/view?usp=share_link',
    //   technologies: ['HTML5','CSS3', 'ES6', 'JavaScript', 'Kodular.io'],
    // },
    {
        type: "front",
        name: "Cabins Romania ",
        description: "App for a Small Business",
        src: "cabins.png",
        repository: "https://github.com/CiprianChiches/cabins-app",
        site: "https://cabinsromania.netlify.app/",
        technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Christmas Stories",
        description: "App for Christmas",
        src: "stories.png",
        repository: "https://github.com/CiprianChiches/christmas-stories",
        site: "https://christmasstoriesbycipi.netlify.app/",
        technologies: ["HTML5", "CSS3", "ES6", "React", "Redux"],
    },
    {
        type: "front",
        name: "Simona Got - Manichiura",
        description: "App for a Small Business",
        src: "simona.png",
        repository: "https://github.com/CiprianChiches/simona-finalapp",
        site: "https://simonagot.netlify.app/",
        technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
    },
    {
        type: "front",
        name: "Cipi's Restaurant - Template ",
        description: "Presentation website",
        src: "restaurant2.png",
        repository: "https://github.com/CiprianChiches/cipi-restaurant",
        site: "https://ciprianrestaurant.netlify.app/",
        technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
    },
    // {
    //     type: "front",
    //     name: "Cipi's Cleaning Services - Template ",
    //     description: "Presentation website",
    //     src: "clean.png",
    //     repository: "https://github.com/CiprianChiches/cleaning-app",
    //     site: "https://cleaningtemplate.netlify.app/",
    //     technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
    // },
    // {
    //     type: "front",
    //     name: "Interior Design - Template ",
    //     description: "Presentation website",
    //     src: "interior.png",
    //     repository: "https://github.com/CiprianChiches/interior-design",
    //     site: "https://interior-design-template2023.netlify.app/",
    //     technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
    // },

    {
        type: "front",
        name: "Cinema Theatre -  Template ",
        description: "Presentation website",
        src: "theatre.png",
        repository: "https://github.com/CiprianChiches/cinema-theatre",
        site: "https://cinemadaciateathre.netlify.app/",
        technologies: ["React", "Redux", "CSS3", "HTML5", "ES6"],
    },
    {
        type: "front",
        name: "NASA -  Photos ",
        description: "Presentation website",
        src: "nasa2.png",
        repository: "https://github.com/CiprianChiches/nasa-marsPhotos",
        site: "https://nasaphotos2023.netlify.app/",
        technologies: ["React", "CSS3", "HTML5", "ES6"],
    },
   
   
  {
    type: "front",
    name: "Winamp ",
    description: "Music App",
    src: "winamp.png",
    repository: "https://github.com/CiprianChiches/winamp-app",
    site: "https://oldwinampdesign.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},

{
    type: "front",
    name: "Gym Template ",
    description: "Presentation website",
    src: "gymnetex.png",
    repository: "https://github.com/CiprianChiches/netex-fitness",
    site: "https://netexfitness-testapp.netlify.app/",
    technologies: ["React","Tailwind CSS", "CSS3", "HTML5", "ES6"],
},
{
    type: "front",
    name: "Gym Template ",
    description: "Presentation website",
    src: "gymtemplate.png",
    repository: "https://github.com/CiprianChiches/gym-template",
    site: "https://gym-template2023.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Personal Portfolio Template ",
    description: "Presentation website",
    src: "personalportfolio.png",
    repository: "https://github.com/CiprianChiches/personal-portfolio",
    site: "https://personal-portfolio-template2023.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Restaurant Website Template ",
    description: "Presentation website",
    src: "restauranttemplate.png",
    repository: "https://github.com/CiprianChiches/restaurant-app2023",
    site: "https://restaurant-template2023.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Movie Studio Template ",
    description: "Presentation website",
    src: "studio.png",
    repository: "https://github.com/CiprianChiches/moviestudio-app",
    site: "https://moviestudio-template.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Interior Design Template ",
    description: "Presentation website",
    src: "templatedesign.png",
    repository: "https://github.com/CiprianChiches/interior-design2023",
    site: "https://my-designtemplate.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Portfolio Template ",
    description: "Presentation website",
    src: "cc-portfolio.png",
    repository: "https://github.com/CiprianChiches/portfolio-template",
    site: "https://cc-portfoliotemplate.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Architecture Template ",
    description: "Presentation website",
    src: "cc-architecture.png",
    repository: "https://github.com/CiprianChiches/architecture-template",
    site: "https://cc-architecture-design.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Portfolio Template ",
    description: "Presentation website",
    src: "portfoliotemplate2.png",
    repository: "https://github.com/CiprianChiches/portfolio-template2",
    site: "https://cc-portfoliotemplate2.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Estate App Template ",
    description: "Presentation website",
    src: "tyche.png",
    repository: "https://github.com/CiprianChiches/estateapp",
    site: "https://tyche-estate.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Products Scan ",
    description: "Food quality indicator",
    src: "qrfood.png",
    repository: "https://github.com/CiprianChiches/food-qr",
    site: "https://foodscannerapp.netlify.app/",
    technologies: ["React", "CSS3", "HTML5", "ES6"],
},
{
    type: "front",
    name: "QR Code Generator ",
    description: "Free QR Code Download",
    src: "qrcode.png",
    repository: "https://github.com/CiprianChiches/qr-code",
    site: "https://qr-generatorapp.netlify.app/",
    technologies: ["React", "CSS3", "HTML5", "ES6"],
},
{
    type: "front",
    name: "Music App ",
    description: "Music Application",
    src: "reactmusic.png",
    repository: "https://github.com/CiprianChiches/music-appreact",
    site: "https://cipimusicapp.netlify.app/",
    technologies: ["React", "CSS3", "HTML5", "ES6"],
},
{
    type: "front",
    name: "Food Recipe ",
    description: "Food Application",
    src: "recipe.png",
    repository: "https://github.com/CiprianChiches/recipe-app",
    site: "https://foodrecipebycipi.netlify.app/",
    technologies: ["React","Parcel Js.","CSS3", "HTML5", "ES6"],
},
{
    type: "front",
    name: "Joinery Business ",
    description: "Presentation Website",
    src: "joinery.png",
    repository: "https://github.com/CiprianChiches/tamplarie-app",
    site: "https://joinerybussines.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Food Blog ",
    description: "Presentation Website",
    src: "foodblog.png",
    repository: "https://github.com/CiprianChiches/food-blog",
    site: "https://foodblogbycipi.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},
{
    type: "front",
    name: "Next Js Portfolio Template ",
    description: "Presentation Website",
    src: "nextportfolio.png",
    repository: "https://github.com/CiprianChiches/-next-jsPortfolio",
    site: "https://next-js-portfolio-phi-henna.vercel.app/",
    technologies: ["React","Next Js","Vercel Deployment"],
},
{
    type: "front",
    name: "James Webb Application ",
    description: "Presentation Website",
    src: "jameswebb.png",
    repository: "https://github.com/CiprianChiches/astronomy-application",
    site: "https://sunny-sunflower-d56214.netlify.app/",
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
},


{
       type: 'android',
       name: 'Youtube Clone',
       description: 'App for Youtube Clone (Beta Version)',
       src: 'android.png',
    //    repository: 'https://github.com/CiprianChiches/cinemaDacia2023',
      site: 'https://youtube-by-cipi.netlify.app/',
   technologies: ['React', 'Kodular.io'],
    },
    {
        type: 'front',
        name: 'Portfolio ',
        description: 'Portfolio website',
        src: 'ildi.png',
      repository: 'https://github.com/CiprianChiches/ildi-portfolio',
       site: 'https://ildiko-szendrei.netlify.app/',
    technologies: ["HTML5", "CSS3", "ES6", "JavaScript"],
     },
];

export default projects;
