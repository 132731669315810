import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import About from './pages/About';
//import Contact from './pages/Contact';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Education from './pages/Education';




import Blog from './pages/Blog';
import Graphics from './pages/Graphics';
// import Skills from './pages/Skills'
import Career from './components/Career';

import AppProvider from './context/AppProvider';

function App() {
  return (
    <AppProvider>
      <Routes>
        <Route exact path="/" element={ <Home /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/projects" element={ <Projects /> } />
        {/* <Route path="/contact" element={ <Contact /> } /> */}

        <Route path="/education" element={ <Education /> } />
        <Route path="/blog" element={<Blog/>}/>
        <Route path="/graphic" element={<Graphics/>}/>
        <Route path="/career" element={<Career/>}/>
        {/* <Route path="/skills" element={<Skills/>}/> */}




      </Routes>
    </AppProvider>
  );
}

export default App;
