import React from "react";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import "../styles/Home.css";
//import image from "../images/img-perfil.png";
import { FiDownload } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";


import MenuMobile from "../components/MenuMobile";
//import Typical from 'react-typical';
import codeman from "../images/codeman.svg";

import { Typewriter } from "react-simple-typewriter";

// import Messenger from "../components/Messenger/Messenger";

function Home() {
    return (
        <body>
            <TitleBar />
            <section className="flex-container">
                <SideBar />
                <section className="main-container">
                    <Header />
                    <MenuMobile />
                    <main className="main-wrapper home">
                        <div className="home-content">
                            <p>
                                <span className="span">{"<"}</span>Hello👋 I'm
                            </p>
                            <h1>
                                Ciprian Chiches
                                <span className="span">{"/>"}</span>{" "}
                            </h1>
                            <span style={{ color: "#FEBE10" }}>
                                <Typewriter
                                    words={[
                                        " Front-end Developer.",
                                        "Android Developer.",
                                        " Astronomy lover.",
                                        " Book addict.",
                                        " Strength athlete.",
                                        " Historian.",
                                        " Comics fan.",
                                    ]}
                                    loop
                                    cursor
                                    cursorStyle="|"
                                    typeSpeed={70}
                                    deleteSpeed={50}
                                    delaySpeed={1000}
                                />
                            </span>

                            {/* <Typical 
                steps={['Front-End Developer', 1000, 'Web Development Student', 1000]}
                loop={Infinity}
                wrapper="h2"
              /> */}
                            <a
                                href="https://drive.google.com/file/d/1aPSycPzTqH_Gv0DBRCgytzpCul5VVCXZ/view?usp=sharing"
                                target="_blank"
                                className="btn-download"
                                rel="noreferrer"
                            >
                                View Resume
                                <FiDownload />
                            </a>

                            <a
                                href="mailto:ciprianchiches@gmail.com"
                                target="_blank"
                                className="btn-download"
                                rel="noreferrer"
                            >
                                Mail
                                <AiOutlineMail />
                            </a>
                        </div>

                        <img src={codeman} alt="" className="image-perfil" />
                    </main>
                </section>

                {/* <SideBar /> */}
            </section>
            {/* <Messenger /> */}

            <Footer />
        </body>
    );
}

export default Home;
