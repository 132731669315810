const education = [
    {
        name: "Certificate",
        message: "Software Development Academy - Romania",
        social: "https://drive.google.com/file/d/1asZWdIxjDe_AAgXz_0oDAFaP88HoNzhg/view?usp=sharing",
    },
    {
        name: "Website",
        message:
            "University of Agricultural Sciences and Veterinary Medicine of Banat - Timisoara",
        social: "https://www.usab-tm.ro/",
    },
    {
        name: "Certificate freeCodeCamp.org",
        message: "          JavaScript Algorithms and Data Structures   ",
        social: "https://drive.google.com/file/d/1jr_diZ-3doIhC-W5wHUGMKqGjNrmjszG/view?usp=sharing",
    },

    {
        name: "Certificate freeCodeCamp.org",
        message: "         Responsive Web Design Certification  ",
        social: "https://drive.google.com/file/d/1O5cqqpV57F2qgxKe3QiTlBKN62S8IfEV/view?usp=sharing",
    },
    {
        name: "Certificate freeCodeCamp.org",
        message: "         Front End Development Libraries ",
        social: "https://drive.google.com/file/d/1HiLHVmT_NwwRMLufjl61abA8kIvbt7Rd/view?usp=share_link",
    },

    {
        name: "Certificate HackerRank",
        message: "React Js Certificate",
        social: "https://drive.google.com/file/d/1rG0fLOprSM0Ur9c4qyL5IyrlWgCt3aFD/view?usp=share_link",
    },
    {
        name: "Certificate Great Learning Academy",
        message: "UI/UX Design Course Certificate",
        social: "https://drive.google.com/file/d/1LQZlBXUJLI4GVoLOB9k2KhPoPJ0k7WjA/view?usp=share_link",
    },

{
    name: " Certificate MindLuster",
    message : " Next Js Certificate",
    social: "https://drive.google.com/file/d/1ZEDxBYb6gpD0Bmb3Nnb8K55fqoQHwPmC/view?usp=share_link",
}
];

export default education;
