import emailjs from '@emailjs/browser';
import { faIdCardAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import "./Email3.css";
import Swal from "sweetalert2";


const Email3 = () => {
    const form = useRef();
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_feeh0z6', 'template_kgb5mav', form.current, '4SN6guJ3uVB7JnJf7')
            .then(  (result) => {
                console.log(result.text);
                Swal.fire({
                    icon: "success",
                    title: "Message Sent Successfully",
                });
            },
            (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: "error",
                    title: "Ooops, something went wrong",
                    text: error.text,
                });
            });
    };
    return (
        <section>
            <Container>
                <div className="section-title-text mb-2" style={{marginTop:"2rem"}}>
                    <h1 className="pt-3 text-start pb-3 text-center" style={{fontSize:"1.7rem",color:"#FEBE10"}}>Let's get to know each other 🤙<br/><br/></h1>
<h1 style={{fontSize:"1.2rem"}}>"Alone we can do so little,together we can do so much." <br/> - Helen Keller</h1>
                </div>

                <div className='contact-form' >
                    <form className="bg-dark" ref={form} onSubmit={sendEmail} >
                        <div className='form-icon' style={{margin:"2rem"}}>
                            <FontAwesomeIcon className='m-5' icon={faIdCardAlt} color="white" size="6x"></FontAwesomeIcon>
                        </div>
                        {/* <label>Name</label> */}
                        <input type="text" name="user_name" placeholder='Your Name' className='item' required />
                        <br />
                        <input type="email" name="user_email" placeholder='Email Address' className='item' required />
                        <br />
                        <textarea name="message" placeholder='Write Down Message' className='item' required />
                        <br />
                        <input type="submit" value="Send" className='item btn btn-outline-info' />
                    </form>
                </div>
            </Container>
        </section>

    );
};

export default Email3;