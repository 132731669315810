import React from "react";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import MenuMobile from "../components/MenuMobile";
import "./Career.css";
//import IconCloud from '../components/IconCloud';
// import left from "../images/left.png";
// import right from "../images/right.png";

// import iconapp from "../images/iconapp.svg";
// import icondev from "../images/icondev.svg";
// import icondesign from "../images/icondesign.svg";
import iconphoto2 from "../images/iconphoto2.png";
import design from "../images/design.png";
import web from "../images/web.png";

import Features from "./Features";

import Email from "./Email";
import contacts from "../data/contacts";
import "../styles/Contact.css";

//import Email2 from "./Email2";
//import Video from "react-responsive-video";
// import SlideShow from "./PhotosSlides";

//import EditorFinal from "./Editor/EditorFinal";

import Email3 from "./Email3/Email3";

import Skills2 from "../pages/Skills2";

import career from "../images/career.jfif";
// import { Slide } from "@mui/material";
// import { Slideshow } from "@mui/icons-material";

function Career() {
    return (
        <body>
            <TitleBar />
            <section className="flex-container">
                <section className="main-container">
                    <Header />
                    <MenuMobile />
                    <main className="main-wrapper about">
                        <div className="abouts-containers">
                            <span className="span-tag html">{"<html>"}</span>
                            <br />
                            <span className="span-tag body">{"<body>"}</span>
                            <br />

                            <span className="span-tag content">{"<h1>"}</span>
                            <h1 className="title-about">
                                {/* <img
                                    src={left}
                                    alt=""
                                    style={{ width: "50px", height: "50px" }}
                                />
                                Career switch
                                <img
                                    src={right}
                                    alt=""
                                    style={{ width: "50px", height: "50px" }}
                                /> */}

                                <img
                                    src={career}
                                    alt=""
                                    style={{
                                        maxWidth: "100%",
                                        borderRadius: "2rem",
                                        border: "3px white solid",
                                    }}
                                />
                            </h1>
                            <br />

                            <span className="span-tag content">{"</h1>"}</span>
                            <br />
                            <span className="span-tag content">{"<p>"}</span>
                            {/* <p className="text-about">In progress...</p> */}

                            <p className="text-about">
                                {/* My name is <span className="span-highlight">Ciprian Chiches.</span> Hi, I’m a Software Developer based in Resita, Romania. For more than a year, I developed beautiful and responsive web applications that satisfied all the needs of clients in order to launch or improve their current businesses.<span className="span-highlight"></span> */}
                            </p>
                            <p className="texting-about">
                                {/* <span className="span-highlight">Soft Skills</span> */}
                                A year and a half ago I decided that it was time
                                to make a professional change.
                                <br />
                                For 7 years I was a Personal Trainer but I
                                needed a new challenge in my life and returned
                                to my first passion, programming. Following the
                                courses held at the Software Development Academy
                                and thousands of hours of individual study, I
                                managed to become a Front End Developer and I
                                want to become a Full Stack Developer in the
                                coming years. If you want to work together,
                                create and develop beautiful things, feel free
                                to contact me.
                                <br />
                                {/* what i am doing start */}
                                <section className="service">
                                    <h3
                                        className="h3 service-title"
                                        style={{ marginTop: "2rem",color:"#FEBE10" }}
                                    >
                                        What I'm doing now
                                    </h3>

                                    <ul className="service-list">
                                        <li className="service-item">
                                            <div className="service-icon-box">
                                                <img
                                                    src={design}
                                                    alt="design icon"
                                                />
                                            </div>

                                            <div className="service-content-box">
                                                <h4 class="h4 service-item-title">
                                                    Web design
                                                </h4>

                                                <p className="service-item-text">
                                                    The most modern and
                                                    high-quality design made at
                                                    a professional level.
                                                </p>
                                            </div>
                                        </li>

                                        <li className="service-item">
                                            <div className="service-icon-box">
                                                <img
                                                    src={web}
                                                    alt="Web development icon"
                                                />
                                            </div>

                                            <div className="service-content-box">
                                                <h4 className="h4 service-item-title">
                                                    Web development
                                                </h4>

                                                <p className="service-item-text">
                                                    High-quality development of
                                                    sites at the professional
                                                    level.
                                                </p>
                                            </div>
                                        </li>
                                        {/* 
                                        <li className="service-item">
                                            <div className="service-icon-box">
                                                <img
                                                    src={iconapp}
                                                    alt="mobile app icon"
                                                />
                                            </div>

                                            <div className="service-content-box">
                                                <h4 className="h4 service-item-title">
                                                    Mobile apps
                                                </h4>

                                                <p className="service-item-text">
                                                    Professional development of
                                                    applications for iOS and
                                                    Android.
                                                </p>
                                            </div>
                                        </li> */}

                                        <li className="service-item">
                                            <div className="service-icon-box">
                                                <img
                                                    src={iconphoto2}
                                                    alt="camera icon"
                                                />
                                            </div>

                                            <div className="service-content-box">
                                                <h4 className="h4 service-item-title">
                                                    Graphic design
                                                </h4>

                                                <p className="service-item-text">
                                                    I build and create print
                                                    materials for small
                                                    businesses and agencies.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </section>
                                {/* what i am doing ends */}
                                {/* <a href="https://pluralsight.com">here</a> */}
                                {/* <a href="https://ciprianblog.netlify.app/">
                                    here
                                </a> */}
                                <br />
                                {/* ... */}
                                <br />
                                {/* ... */}
                                <br />
                                {/* ...{" "} */}
                                {/* <Video

                                    webm={`videoEu.mp4`}
                                    height={[320, 400, 480, 560].map(
                                        (n) => n + "px"
                                    )}
                                    objectFit={`contain`}
                                /> */}
                                <Features />
                              
<Email3/>

                                {/* Contact final */}
                                {/* <Skills2 /> */}
                                {/* slides inceput*/}
                                {/* <SlideShow  /> */}
                                {/* slides final */}
                            </p>
                            <p className="text-about last">
                            {/* Relevant skills include: <span className="span-highlight">Html, CSS, JavaScript, React, Redux, ContextAPI, Hooks, Jest/RTL, Git/Github.</span> */}

                            {/* <p className="text-about"> */}
                            {/* <span className="span-highlight">Soft Skills</span> */}
                            {/* <br /> */}
                            {/* Fast learner, continuous drive for self-improvement and self-motivation. */}
                            {/* <br /> */}
                            {/* {/* Consistency in organizing and planning tasks. */}
                            <br />
                            {/* <h1 className="title-about" style={{fontSize:"1.5rem"}}>My Skills</h1>
                                    <br />
                                    Funny Editor
                                    <br />
                                    Have fun ! 🤙 <br />
                                    Make a list of your skills ! */}
                            {/* </p> */}
                            {/* <EditorFinal /> */}
                            </p>

                            <span className="span-tag content">{"</p>"}</span>
                            <br />
                            <span className="span-tag body">{"</body>"}</span>
                            <br />
                            <span className="span-tag html">{"</html>"}</span>
                        </div>
                        <Skills2 />

                        {/* <IconCloud /> */}
                        {/* <Writer /> */}
                    </main>
                </section>
                                        {/* <Skills2 /> */}


                <SideBar />
            </section>
            <Footer />
        </body>
    );
}

export default Career;
