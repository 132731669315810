import React from "react";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import MenuMobile from "../components/MenuMobile";
import '../styles/About.css';
import IconCloud from '../components/IconCloud';

function About() {
  return ( 
    <body>
      <TitleBar />
      <section className="flex-container">
        <section className="main-container">
          <Header />
          <MenuMobile />
          <main className="main-wrapper about">
            <div className="about-container">
              <span className="span-tag html">{'<html>'}</span>
              <br/>
              <span className="span-tag body">{'<body>'}</span>
              <br/>
              <span className="span-tag content">{'<h1>'}</span>
                <h1 className="title-about">Hello,</h1>
              <span className="span-tag content">{'</h1>'}</span>
              <br/>
              <span className="span-tag content">{'<p>'}</span>
                <p className="text-about">
                 My name is <span className="span-highlight">Ciprian Chiches.</span> Hi, I’m a Front-End and Android Developer based in Resita, Romania. For more than a year, I developed beautiful and responsive web applications that satisfied all the needs of clients in order to launch or improve their current businesses.<span className="span-highlight"></span>
                </p>
                <p className="text-about">
                  <span className="span-highlight">Soft Skills</span>
                  <br/>
                  Fast learner, continuous drive for self-improvement and self-motivation.
                  <br/>
Consistency in organizing and planning tasks.
<br/>
Hard working with a strong motivation to bring on the best possible results.
<br/>
Problem-solver and decision making while taking full responsability of my actions.
                </p>
                <p className="text-about last">
                  Relevant skills include: <span className="span-highlight">Html, CSS, JavaScript, React, Redux, ContextAPI, Hooks,Node Js,Express Js,Mongo DB,HTTP Jest/RTL, Git/Github.</span>
                </p>
              <span className="span-tag content">{'</p>'}</span>
              <br/>
              <span className="span-tag body">{'</body>'}</span>
              <br/>
              <span className="span-tag html">{'</html>'}</span>
            </div>
            <IconCloud />
          </main>
        </section>
        <SideBar />
      </section>
      <Footer />
    </body>
  );
}

export default About;