import React from "react";
import "./Features.css";
import talk from "../images/talk.png";
import planing from "../images/planing.png";
import design from "../images/design.png";
import coding from "../images/coding.png";
import gear from "../images/gear.png";
import site from "../images/site.png";

function Features() {
    return (
        <div>
            <section className="featuress" id="featuress">
                <h1
                    className="title-abouts"
                    style={{ fontSize: "1.5rem", marginBottom: "2rem",color:"#FEBE10" }}
                >
                    6 Short Steps Towards Your Brand New Website
                </h1>

                <div className="box-containers">
                    <div className="boxs">
                        {/* <i className="fas fa-magic"></i> */}
                        <img src={talk} alt="" />
                        <h3>1.Discussion</h3>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque enim ad neque. Voluptatem alias beatae accusamus laudantium nisi assumenda libero.</p> */}
                    </div>
                    <div className="boxs">
                        {/* <i class="fas fa-shield-alt"></i> */}
                        <img src={planing} alt="" />

                        <h3>2.Planning</h3>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque enim ad neque. Voluptatem alias beatae accusamus laudantium nisi assumenda libero.</p> */}
                    </div>
                    <div className="boxs">
                        {/* <i class="fas fa-globe"></i> */}
                        <img src={design} alt="" />

                        <h3>3.Design</h3>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque enim ad neque. Voluptatem alias beatae accusamus laudantium nisi assumenda libero.</p> */}
                    </div>
                    <div className="boxs">
                        {/* <i class="fas fa-map-marker-alt"></i> */}
                        <img src={coding} alt="" />

                        <h3>4.Coding</h3>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque enim ad neque. Voluptatem alias beatae accusamus laudantium nisi assumenda libero.</p> */}
                    </div>
                    <div className="boxs">
                        {/* <i class="fas fa-cogs"></i> */}
                        <img src={gear} alt="" />

                        <h3>5.Programming</h3>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque enim ad neque. Voluptatem alias beatae accusamus laudantium nisi assumenda libero.</p> */}
                    </div>
                    <div className="boxs">
                        {/* <i class="fas fa-headset"></i> */}
                        <img src={site} alt="" />

                        <h3>6.Website is Ready !</h3>
                        {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque enim ad neque. Voluptatem alias beatae accusamus laudantium nisi assumenda libero.</p> */}
                    </div>

                    
                </div>
                
            </section>
        </div>
    );
}

export default Features;
